<form [formGroup]="cookiesForm">
  <div class="title-container">
    <h4 translate="portal.cookies.title"></h4>
  </div>
  <!-- todo stop using innerhtml. rework the translations and the way they use links and such-->
  <p [innerHTML]="'portal.cookies.body' | translate"></p>
  <mat-divider></mat-divider>

  <div class="toggle-container">
    <div class="toggle-group">
      <p translate="portal.cookies.functional.body"></p>
      <mat-slide-toggle
        class="button-toggle"
        color="primary"
        formControlName="settingsFunctional"
      >
        <span translate="portal.generic.on"></span>
      </mat-slide-toggle>
    </div>

    <mat-divider></mat-divider>

    <div class="toggle-group">
      <p translate="portal.cookies.tracking.body"></p>
      <mat-slide-toggle
        (change)="trackingChanged($event)"
        color="primary"
        formControlName="settingsTracking"
      >
        <p>
          {{
            (settings().tracking ? 'portal.generic.on' : 'portal.generic.off')
              | translate
          }}
        </p>
      </mat-slide-toggle>
    </div>
  </div>

  <br />
  <div class="save-button-container">
    <button (click)="save()" color="primary" mat-flat-button type="submit">
      <b translate="portal.generic.save"></b>
    </button>
  </div>
</form>
