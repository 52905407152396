import { Injectable } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@component/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogConfig } from '@model/confirmation-dialog-config';

import { CookiesComponent } from '../cookies/cookies.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly matBottomSheet: MatBottomSheet,
  ) {}

  showConfirmationDialog(
    config: ConfirmationDialogConfig,
  ): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: config,
    });
  }

  openCookieFooter(): MatBottomSheetRef<CookiesComponent> {
    return this.matBottomSheet.open(CookiesComponent);
  }
}
