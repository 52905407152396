import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle';
import { CookieSettings } from '@model/cookieSettings';
import { SettingsCookieService } from '@service/settings-cookie.service';
import { MatDivider } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'svnl-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
  imports: [
    MatDivider,
    MatSlideToggle,
    TranslateModule,
    ReactiveFormsModule,
    MatButton,
  ],
})
export class CookiesComponent implements OnInit {
  cookiesForm: FormGroup;

  settings = signal<CookieSettings | undefined>(undefined);

  private bottomSheetRef = inject(MatBottomSheetRef);
  private formBuilder = inject(FormBuilder);
  private settingsCookieService = inject(SettingsCookieService);

  ngOnInit(): void {
    this.createForm();

    this.settings.set(this.settingsCookieService.getCookieSettings());
    if (this.settings() === null) {
      this.settings.update(value => ({
        ...value,
        tracking: true,
        functional: true,
      }));
      this.settingsCookieService.setCookieSettings(this.settings());
    }
    this.cookiesForm.get('settingsTracking').setValue(this.settings().tracking);
  }

  save(): void {
    this.settingsCookieService.setCookieSettings(this.settings());
    this.bottomSheetRef.dismiss();
  }

  trackingChanged(event: MatSlideToggleChange): void {
    this.settings.update(value => ({
      ...value,
      tracking: event.checked,
    }));
  }

  private createForm(): void {
    this.cookiesForm = this.formBuilder.group({
      settingsFunctional: { value: true, disabled: true },
      settingsTracking: false,
    });
  }
}
